import React from 'react'
import Header from '../inc/Header'
import DrSunilsir from "../images/dr.sunil.jpg";

function Head() {
    return (
        <div>
            <Header />
            <br />

            <br />
            <br />

            <br />

            <br /><br />
            <div className="album py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-3">

                            <div className="card-body">
                                <h3>About Me...</h3>
                                <h4 style={{ color: "grey" }} className="card-title mb-3">Mr. Sunil Kumar</h4>
                                <p className="card-text mb-2">Myself Sunil Kumar. I am from Delhi. I worked  as an Indian Army for 15 years.</p>
                                <p>I am a very dedicated and Committed and a very Hardworking person. I am Working as A Sales And Marketing Head since 12 years... I am very passionate towards my carrier...

                                    I want to become a very successful person and not only me I want to make my team successful as well...

                                    And for that I can face all the circumstances in life..</p>
                            </div>


                        </div>
                        <div className="col-md-6">
                            <div className='imagesradius'>
                                <img src={DrSunilsir} className="card-img-top" alt="SunilKumar" />
                            </div>



                        </div>
                    </div>

                </div>
            </div>
            <br />

            
        </div>
    )
}

export default Head