import React from 'react'
import Header from '../inc/Header'

function Disclaimer() {
  return (
    <div>
      <Header/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br />
            <br />
      <center><h3 style={{color:"grey"}} >DISCLAIMER</h3></center>
      <br/>
      <br/>
      <div className="album py-3">
        
        <div className="container">
 
        
            <div className="row ">
      <h5 className='mb-3' ><b>DISCLAIMER= RISKS ASSOCIATED WITH FOREX TRADING</b></h5>
      <h6 className='texts' >Trading in foreign exchange (“Forex”) on margins entails high risk and is not suitable for all investors. Past performance is not an indication of future results. In this case, as well, the high degree of leverage can act both against you and for you. Before you decide to invest in foreign exchange, you should carefully assess your investment objectives, experience, financial possibilities and willingness to take risks. There is a possibility that you will lose your initial investment partially or completely. Therefore, you should not invest any funds that you cannot afford to completely lose in a worst-case scenario. You should also be aware of all the risks associated with foreign exchange trading and contact an independent financial advisor in case of doubt. We are not responsible for your any loss or profit !</h6>
      </div>
      </div>
      </div>
    </div>
  )
}

export default Disclaimer