import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'



function Notice() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [notice, setNotice] = useState([])
      const navigate = useNavigate()
  
      const noticeCollectionRef = collection(db, "notice");
      useEffect(() => {
  
          const getNotice = async () => {
              const data = await getDocs(noticeCollectionRef);
              setNotice(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getNotice()
      }, [])
    return (
      
<>







            {notice.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((safeforexreturn) => { return (
       
            
        
                       
                            <div className="card-body">
                                <img src={safeforexreturn.img} className="card-img-top mb-2" alt=""/>

                                <h6 style={{color:"maroon"}} className="card-text" mb-1> {safeforexreturn.title}</h6>
                                <a href={safeforexreturn.link} target='_black'>{safeforexreturn.text}</a>
                                <p style={{color:"maroon"}} className="card-text"> {safeforexreturn.date}</p>
                              


                               {/* <Link to="/onlineclass"> <h6 style={{color:"Darkblue"}} >{safeforexreturn.link}</h6> </Link> */}

                        
                                {/* <h5 style={{color: "Darkblue"}}className="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={notice.length}
                /> */}
                           

</>
        
        );
    }
    
    export default Notice;