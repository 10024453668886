
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Main from "./components/pages/Main";
import Tchart  from "./components/pages/Tchart";

import Footer from "./components/inc/Footer";
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import Book from './components/pages/Book';
import Ecalendar from './components/pages/Ecalendar';
import Forexbrokers from './components/pages/Forexbrokers';
import Supportpage from './components/pages/Supportpage';

import ProtectedRoute from './components/pages/ProtectedRoute';
import UserDashboard from './components/pages/UserDashboard';
import UserAuthContextProvider from './contexts/UserAuthContext';
import Cryptochart from './components/pages/Cryptochart';
import Indiceschart from './components/pages/Indiceschart';
import Euranalysis from './components/pages/Euranalysis';
import DocConsult from './components/pages/DocConsult';
import FundManagerJob from './components/pages/FundManagerJob';
import Managefund from './components/pages/Managefund';
import Pindicator from './components/pages/Pindicator';
import Multiplechart from './components/pages/Multiplechart';
import Goldchart from './components/pages/Goldchart';
import Signals from './components/pages/Signals';
import Course from './components/pages/Course';
import Analysis2 from './components/pages/Analysis2';

import Analysis3 from './components/pages/Analysis3';
import Analysis4 from './components/pages/Analysis4';
import Analysis5 from './components/pages/Analysis5';
import Analysis6 from './components/pages/Analysis6';
import DocGbpAnalysis from './components/pages/DocGbpAnalysis';
import DocGold from './components/pages/DocGold';
import Disclaimer from './components/pages/Disclaimer';
import Mission from './components/pages/Mission';
import Per2022 from './components/pages/Per2022';
import Per2023 from './components/pages/Per2023';
import Per2024 from './components/pages/Per2024';
import Bmodel from './components/pages/Bmodel';
import Head from './components/pages/Head';
import Protraders from './components/pages/Protraders';
import Online from './components/pages/Online';
import Performance from './components/pages/Performance';


function App() {
  return (
    <Router>
 <UserAuthContextProvider>
      <div>



        <Routes>

          <Route axact path="/" element={<Main />} />
          <Route axact path="/login" element={<Login />} />
            <Route axact path="/signup" element={<Signup />} />
            <Route axact path="/book" element={<Book />} />
            <Route axact path="/cryptochart" element={<Cryptochart />} />
            <Route axact path="/indiceschart" element={<Indiceschart/>} />
            <Route axact path="/ecalendar" element={<Ecalendar />} />
            <Route axact path="/euranalysis" element={<Euranalysis />} />
            <Route axact path="/support" element={<Supportpage />} />
            <Route axact path="/Fundmanagerjob" element={<FundManagerJob />} />
            <Route axact path="/bestForexbrokers" element={<Forexbrokers />} />
            <Route axact path="/Multiplechart" element={<Multiplechart />} />
            <Route axact path="/Goldchart" element={<Goldchart />} />
            <Route axact path="/CoursePremium" element={<Course />} />
            
            <Route axact path="/Gbpanalysis" element={<Analysis2 />} />
            <Route axact path="/goldanalysis" element={<Analysis3 />} />
            <Route axact path="/cadanalysis" element={<Analysis4 />}/> 
            <Route axact path="/jpyanalysis" element={<Analysis5 />}/> 
            <Route axact path="/chfanalysis" element={<Analysis6 />}/> 
            <Route axact path="/Disclaimer" element={<Disclaimer />}/> 
            <Route axact path="/Mission" element={<Mission />}/> 
            <Route axact path="/Per2022" element={<Per2022 />}/> 
            <Route axact path="/Per2023" element={<Per2023 />}/> 
            <Route axact path="/Per2024" element={<Per2024 />}/> 
            <Route axact path="/Businessmodel" element={<Bmodel/>}/> 
            <Route axact path="/tchart" element={<Tchart/>}/> 
            <Route axact path="/Head" element={<Head/>}/> 
            <Route axact path="/Protraders" element={<Protraders/>}/> 
            <Route axact path="/Performance" element={<Performance/>}/> 
         
          <Route axact path="/managefund" element={<ProtectedRoute> <Managefund /> </ProtectedRoute>} />
          <Route axact path="/Pindicator" element={<ProtectedRoute> <Pindicator /> </ProtectedRoute>} />
          <Route axact path="/Signals" element={<ProtectedRoute> <Signals /> </ProtectedRoute>} />
          <Route axact path="/JOIN-FREE-TRAINING" element={<ProtectedRoute> <Online /> </ProtectedRoute>} />

          <Route axact path="/UserDashboard/:userId" element={<ProtectedRoute> <UserDashboard /> </ProtectedRoute>} />
          <Route path="/MorePhoto/:docId" element={<DocConsult/>}/>
          <Route path="/Photo/:docId" element={<DocGbpAnalysis/>}/>
          <Route path="/Photos/:docId" element={<DocGold/>}/>
          </Routes>
          <Footer />

        </div>
        </UserAuthContextProvider>
    </Router>
  );
}

export default App;
