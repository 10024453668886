import React from 'react'
import Header from '../inc/Header'
import Inicators from "../images/imagesin.jpeg";
import ReactPlayer from "react-player";
function Pindicator() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <center><h3><b>Premium Indicator</b></h3></center>
            <div className="album py-3">

                <div className="container">
                    <div className="row ">
                        <img src={Inicators} className="d-block w-100" alt="exness_with_safe_forex-return" />
                    </div>
                </div>
            </div>

            <div className="album py-3">
<div className="container">
<div className="row ">
            <ReactPlayer  width="100%" height="340px" url="https://www.youtube.com/watch?v=e0TqYpuTQoM&t=1s"/>
            </div>
            </div>
            </div>
        </div>
    )
}

export default Pindicator