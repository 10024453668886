import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'
import Header from '../inc/Header';
// import {Link} from "react-router-dom";
// import ScrollToTop from "react-scroll-to-top";




function Per2024 () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(20);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [performance2024, setPerformance2024] = useState([])
      const navigate = useNavigate()
  
      const performance2024Ref = collection(db, "Performance2024");
      useEffect(() => {
  
          const getPerformance2024 = async () => {
              const data = await getDocs(performance2024Ref);
              setPerformance2024(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getPerformance2024()
      }, [])
    return (
      
<>
<Header/>

{/* <br/>
<ScrollToTop smooth top="100" color="maroon" /> */}

<br/>
<br/>

            


<center><h3 style={{ color: "grey" }} >Performance 2024</h3></center>
<center><h4 style={{ color: "Darkblue" }}>YEARLY PERCENTAGE =</h4></center>
            <br/>
<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
                    
          
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {performance2024.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.month.toLowerCase().includes(searchTerm.toLowerCase()) 
  
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((performance2024) => { return (
            <div className="col-md-8" >
                       
                      
                            <div className="card-body">

                                
                                <div class="card border-primary mb-3" >
                                <div className="d-flex justify-content-between align-items-center"> 
  <div class="card-header">{performance2024.month}


  </div>
  <h6 style={{color: "Darkblue"}}className="card-text mb-0 me-3"> {performance2024.rate} </h6>
  </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={performance2024.length}
                /> */}


                            </div>
                            </div>

</>
        
        );
    }
    
    export default Per2024;


