import React from 'react'
import Header from '../inc/Header'
import MissionVission from "../images/mission-vision.jpg";

function Mission() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <center><h3 style={{ color: "grey" }} >MISSION</h3></center>
            <br />
            <br />
            <div className="album py-3">

                <div className="container">


                    <div className="row">

                        <div className="col-md-4">
                           
                                <img src={MissionVission} class="card-img-top" alt="..." />
                              
                           
                        </div>
                        <div className="col-md-8">
                            
                               
                                <div className="card-body">
                                 
                                    <p className="card-text">Generate a consistent passive income for all . We want to make a fruitful relationship with our investors.</p>

                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default Mission