import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'




// import { Typewriter } from 'react-simple-typewriter' 




function Cadanalysis () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(8);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [cadanalysis, setCadanalysis] = useState([])
      const navigate = useNavigate()
  
      const cadanalysisCollectionRef = collection(db, "USDCADadanalysis");
      useEffect(() => {
  
          const getcadanalysis = async () => {
              const data = await getDocs(cadanalysisCollectionRef);
              setCadanalysis(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getcadanalysis()
      }, [])
    return (
      
<>
<br/>



{/* <h1 className="card-text mb-2" style={{paddingTop: "1rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Search <span style={{color: "purple", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={90}
    deleteSpeed={50}
    delaySpeed={1000}
    words={[ " Health Website", "Magazine Website", "News Media Website", "Photographer & Studio Website", "Education Website", "Jewellers Website", "Personal Website ", "Any Shop Website", "Tax Company"]}
    />
</span>
</b></h1> */}
<div className="album py-0">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search..." name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {cadanalysis.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 

    
      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((cadanalysis) => { return (
       
            <div className="col-md-6" >
                        <div className="card mb-5 border-White box shadow">
                            <button className='btn btn-outline-success'>{cadanalysis.date}</button>
                        <img onClick={() => {navigate(`/Photos/${cadanalysis.id}`)}} className="card-img-top" src={cadanalysis.img}   alt={cadanalysis.title} />
                      
                       
                        <p onClick={() => {navigate(`/Photos/${cadanalysis.id}`)}} type="button" >
                                          
                                  

                            <div className="card-body">

                            <h5 className="card-text mb-3">{cadanalysis.title}</h5>
                  
                        
                            <p style={{color:"grey"}} className="card-text"> {cadanalysis.des}</p>
                           
                         

                            </div>
                            </p>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={cadanalysis.length}
                /> */}
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Cadanalysis;