// import React from 'react'
// import Header from '../inc/Header'

// function Per2022() {
//     return (
//         <div>
//             <Header />
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />

//             <center><h3 style={{ color: "grey" }} >Performance 2022</h3></center>
//             <br/>
//             <div className="album py-2">

//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">January</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">14.53%</p>
//                             </div>
//                         </div>
//                         <hr />
//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">February</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">26.17%</p>
//                             </div>
//                         </div>
//                         <hr />
//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">March</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">	32.81%</p>
//                             </div>
//                         </div>
//                         <hr />
//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">April</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">	32.81%</p>
//                             </div>
//                         </div>
//                         <hr />

//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">May</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">18.36%</p>
//                             </div>
//                         </div>
//                         <hr />


//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">June</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">	14.2%</p>
//                             </div>
//                         </div>
//                         <hr />

//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">July</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">22.1%</p>
//                             </div>
//                         </div>
//                         <hr />

//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">August</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">30.37%</p>
//                             </div>
//                         </div>
//                         <hr />
                        
//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">September</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">24.5%</p>
//                             </div>
//                         </div>
//                         <hr />
                        
//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">October</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">	8.56%</p>
//                             </div>
//                         </div>
//                         <hr />
                        
//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">November</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">12.53%</p>
//                             </div>
//                         </div>
//                         <hr />
//                         <div className="col-md-7 mb-2">
//                             <p className="card-text">December</p>
//                         </div>
//                         <div className="col-md-4">
//                             <div className="card-body">
//                                 <p className="card-text">	4.2%</p>
//                             </div>
//                         </div>
                
//                     </div>
//                 </div>
//             </div>
//             <br />
//             <br />
//             <br />
//             <br />
//         </div>
//     )
// }

// export default Per2022






import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'
import Header from '../inc/Header';

// import ScrollToTop from "react-scroll-to-top";




function Per2023 () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(14);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [performance2023, setPerformance2023] = useState([])
      const navigate = useNavigate()
  
      const performance2023Ref = collection(db, "Performance2023");
      useEffect(() => {
  
          const getPerformance2023 = async () => {
              const data = await getDocs(performance2023Ref);
              setPerformance2023(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getPerformance2023()
      }, [])
    return (
      
<>
<Header/>

<br/>
{/* <ScrollToTop smooth top="100" color="maroon" /> */}


<br/>
<br/>



<center><h3 style={{ color: "grey" }} >Performance 2023</h3></center>
<center><h4 style={{ color: "Darkblue" }}>YEARLY PERCENTAGE =</h4></center>
            <br/>
<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
                    
          
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {performance2023.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.month.toLowerCase().includes(searchTerm.toLowerCase()) 
  
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((performance2023) => { return (
            <div className="col-md-8" >
                       
                      
                            <div className="card-body">

                               
                                <div class="card border-primary mb-3" >
                                <div className="d-flex justify-content-between align-items-center"> 
  <div class="card-header">{performance2023.month}


  </div>
  <h6 style={{color: "Darkblue"}}className="card-text mb-0 me-3"> {performance2023.rate} </h6>
  </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={performance2023.length}
                /> */}


                            </div>
                            </div>

</>
        
        );
    }
    
    export default Per2023;


