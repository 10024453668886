import React from 'react'
import Header from "../inc/Header";
import ReactPlayer from "react-player";
import performance2023 from "../images/tradingimage.jpg"
import performancemrmondal from "../images/image_per.jpg"
import Downloadmy from "../images/download.jpg";
import Per2022 from './Per2022';
import Per2023 from './Per2023';
import Per2024 from './Per2024';
import ScrollToTop from "react-scroll-to-top";

function Performance() {
    return (
        <div>
            <Header />

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="maroon" />
            <div className="album py-2">

                <div className="container">


                    <div className="row">

                        <div className="col-md-4">
                            <ReactPlayer width="100%" height="340px" url="https://www.youtube.com/watch?v=DS1tD5Gqczg" />
                        </div>
                        <div className="col-md-4">
                            <div class="card">
                                <img src={performance2023} class="card-img-top" alt="performance2023" />

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="card">
                                <img src={performancemrmondal} class="card-img-top" alt="performancemrmondal" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="album py-3">

                <div className="container">


                    <div className="row">
                        <div class="card" >
                            <img src={Downloadmy} class="card-img-top" alt="myfx" />
                            <div class="card-body">
                                <h5 class="card-title">EURUSD SMC</h5>

                                <p class="card-text">SEE LIVE PROOF </p>
                                <a href="https://www.myfxbook.com/members/tradexpert1/eurusd-smc/1046" target='_blank' class="btn btn-primary">Click Now <div class="spinner-grow text-light spinner-grow-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div></a>

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Per2022 />

            <Per2023 />
            <Per2024 />
        </div>
    )
}

export default Performance