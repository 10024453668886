import React from 'react'
import Header from '../inc/Header'
import Proslider from '../inc/Proslider';

function Protraders() {
    return (
        <div>
            <Header />
            <br />

            <br />
            <br />
          
          <br/>
        
          <br/><br/>

            <div className="album py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                           
                              <Proslider/>
                               
                            
                        </div>
                        <div className="col-md-6">
                        
                          
                                <div className="card-body">
                                    <h4 style={{color:"grey"}} className="card-title mb-3">Mr. M MONDAL:-</h4>
                                    <p className="card-text">"Myself Mr M MONDAL from India 🇮🇳 
I am a Professional  forex fund manager!
I am successfully doing forex trading last 9 years+
I have investors from all over the globe 🌎 
I can generate consistent profit for my clients!
Thanks 😊 to all for choosing me as a fund manager !
My telegram channel 

<a href='https://t.me/onlinemoney99' target='_blank'> https://t.me/onlinemoney99</a></p>
                                  
                                </div>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Protraders