import React from 'react'
import Header from '../inc/Header'
// import Business from "../images/business.jpg";
// import { Link } from "react-router-dom";
import Businessm from "../pages/Businessm";
import "./Bmodel.css";

function Bmodel() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
           
    
            <div class="backrool">
                <br/>
                <br/>
                <div className='headingmodel'>
                   
            <center > <h1 style={{ color: "white" }}> OUR BUSINESS MODEL</h1></center>
            </div>
          
            <div className="album py-3">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div class="card text-white bg-light mb-3">

                                <div class="card-header">  <marquee> <h2 style={{ color: "Darkblue" }} > OUR BUSINESS PLAN</h2></marquee></div>
                                <div class="card-body">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h5 style={{ color: "red" }} class="card-title">RISK MODE</h5>
                                        <h5 style={{ color: "green" }} class="card-title">AVERAGE RETURN AFTER 3 MONTHS</h5>
                                        <h5 style={{ color: "red" }} class="card-title">MAX DRAW DOWN</h5>
                                        <h5 style={{ color: "green" }} class="card-title">YEARLY RETURN</h5>
                                    </div>
                                    <hr style={{ color: "black" }} />
                                    <div className="d-flex justify-content-between align-items-center">

                                        <h5 style={{ color: "red" }} class="card-title">LOW RISK MODE</h5>
                                        <h5 style={{ color: "green" }} class="card-title">12% - 20%</h5>
                                        <h5 style={{ color: "red" }} class="card-title">10%</h5>
                                        <h5 style={{ color: "green" }} class="card-title">MIN 60%</h5>
                                    </div>
                                    <hr style={{ color: "black" }}/>
                                    <div className="d-flex justify-content-between align-items-center">

                                        <h5 style={{ color: "red" }} className="card-title">MEDIUM RISK MO</h5>
                                        <h5 style={{ color: "green" }} className="card-title">30% - 45%</h5>
                                        <h5 style={{ color: "red" }} className="card-title">25%</h5>
                                        <h5 style={{ color: "green" }} className="card-title">MIN 100%</h5>
                                    </div>
                                    <hr style={{ color: "black" }}/>
                                    <div className="d-flex justify-content-between align-items-center">

                                        <h5 style={{ color: "red" }} className="card-title">HIGH RISK MODE</h5>
                                        <h5 style={{ color: "green" }} className="card-title">60% - 80%</h5>
                                        <h5 style={{ color: "red" }} className="card-title">50%</h5>
                                        <h5 style={{ color: "green" }} className="card-title">MIN 200%</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-2">
                            <div className="card">
                                <button className='btn btn-success'>YEARLY RETURN</button>
                                <button className='btn btn-primary'>MIN 60%</button>
                                <button className='btn btn-warning'>MIN 100%</button>
                                <button className='btn btn-danger'>MIN 200%</button>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

       
            {/* 
            <div className="album py-3">

                <div className="container">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="card">
                                <div className="card-header"> <center style={{ color: "Darkblue" }} > BUSINESS PLAN</center> </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="card-header"> Featured  </div>
                                    <div className="card-header"> Featured  </div>
                                    <div className="card-header"> Featured  </div>
                                </div>
                                <hr className='mb-0' />
                                <ul className="list-group list-group-flush">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <li className="list-group-item">An item</li>
                                        <li className="list-group-item">An item</li>
                                        <li className="list-group-item">An item</li>
                                    </div>
                                    <hr className='mb-0' />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <li className="list-group-item">A second item</li>
                                        <li className="list-group-item">A second item</li>
                                        <li className="list-group-item">A second item</li>

                                    </div>
                                    <hr className='mb-0' />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <li className="list-group-item">A third item</li>
                                        <li className="list-group-item">A third item</li>
                                        <li className="list-group-item">A third item</li>
                                    </div>
                                    <hr className='mb-0' />
                                </ul>
                            </div>



                        </div>
                  
                    </div>
                </div>
            </div> */}



            <div className="album py-3">

                <div className="container">


                    <div className="row">
                        <div className="col-md-10">
                            <div class="card text-dark bg-light mb-3" >
                                <div class="card-header">BUSINESS MODEL</div>
                                <div class="card-body">
                                    <Businessm/>
                                    

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div className="album py-3">

                <div className="container">


                    <div className="row">

                        <div className="col-md-8">
                            <div className="card-body">

                                <p className="card-text texts">ACCOUNT MANAGEMENT SERVICES =
                                    EARN MONTHLY AVERAGE PROFIT (5-10) % IN YOUR OWN ACCOUNT, OWN BROKER  !
                                    NO NEED TO TRUST ANY  COMPANY OR ANYONE !
                                    YOUR MONEY IN  YOUR CONTROL !
                                    WE ONLY TRADE FOR YOU !
                                    NO MLM !
                                    NO INVESTMENT PLAN!
                                    WE TRADE ONLY FULLY REGULATED LICENSED  BROKER !
                                    <Link style={{ color: "blue" }} to="/bestForexbrokers"> CHOOSE FULLY REGULAED BROKER   LIST (LINK) </Link>
                                    AFTER DEPOSIT (MIN 1000$ FOR PERSONAL ACCOUNT MANAGEMENT ) SEND US MT4/MT5 <Link to="/managefund"> DETAILS HERE (LINK)</Link></p>
                                <p>THERE ARE 2 TYPES OF ACCOUNT MANAGEMENT SERVICES = </p>

                                <p className='mb-3' > <b>A)</b>  PERSONAL ACCOUNT MANAGEMENT SERVICES <b> B)</b>  COPY TRADING SERVICES </p>
                                <p className="card-text texts" > A) PERSONAL ACCOUNT MANAGEMENT SERVICES = MIN INVESTMENT =1000$ WE TRADE ON YOUR ACCOUNT AND GENERATE MONTHLY AVERAGE (5-10)% . MONTHLY PROFIT SHARE 60-40. 60% GOES TO INVESTORS AND 40% GOES TO OUR COMPANY ! SUPPOSE , INVESTOR JOHN GIVES US 10,000$ ACCOUNT FOR MANAGEMENT IN JANUARY , WE MADE 10% PROFIT IN JANUARY MONTH MEANS 1000$ PROFIT NOW JOHN WILL TAKE 60% = 600$ OF 1000$ PROFIT SHARE ! AND OUR COMPANY WILL TAKE 40%=400$ AS A PROFIT SHARE ! </p>

                                <p>B) COPY TRADING SERVICE= COPY TRADING STARTS WITH 40 $ ONLY *HOW TO GENERATE MONTHLY 10% PROFIT WITHOUT ANY KNOWLEDGE BY COPY TRADING* ???? STEPS - 🏋️‍♂️</p>
                                <p className="card-text texts">STEPS - 🏋️‍♂️

                                    1) OPEN AN ACCOUNT WITH TOP REGULATED LICENSED BROKER EXNESS BY USING THIS LINK –( COPY THE LINK ADDRESS AND PASTE ON YOUR BROWSER IF YOU DO NOT HAVE EXNESS ACCOUNT )

                                    <a href=' https://www.exness.com/a/gako2ysyyk'>  https://www.exness.com/a/gako2ysyyk</a>

                                    ( PLZ SAVE YOUR LOG IN DETAILS IN YOUR DIARY 📔)</p>
                                <p className="card-text texts" >2) VERIFY THIS ACCOUNT BY PUTTING YOUR 🆔 PROOF AND ADDRESS PROOF !  IT TAKES MAX 1-2 DAYS ! AFTER VERIFICATION YOU WILL RECEIVE AN EMAIL THAT YOUR ACCOUNT IS VERIFIED ✅</p>
                                <p className='texts'>3) NOW , OPEN PLAYSTORE /APPS STORE FROM YOUR PHONE 📱
                                    AND SEARCH 🔍 FOR EXNESS SOCIAL TRADE APP,
                                    DOWNLOAD IT AND LOG IN WITH SAME LOGIN DETAILS ( WHICH YOU NOTED IN STEP NO 1).
                                    AFTER THAT , GO TO DEPOSIT 💸 OPTION- YOU CAN DEPOSIT MIN 10$ TO START 🛫
                                    NOW YOU CAN COPY MY ALL TRADES AUTOMATICALLY!
                                    THERE IS SO MANY  DEPOSIT METHODS !
                                    WHEN DEPOSIT DONE ✅ REFRESH THIS APP AND MINIMIZE IT !</p>
                                <p>4) NOW CLICK ON THIS COPY TRADING LINK ON YOUR MOBILE BROWSER-

                                    <a href='https://social-trading.exness.com/strategy/110048936'> https://social-trading.exness.com/strategy/110048936</a>
                                </p>
                                <p>5) YOU WILL SEE THE START COPY OPTION THERE 💸  CLICK ON IT !

                                    CONGRATULATIONS 🥳 ALL DONE ✅ </p>
                                <br />
                                <h5>COPY MY ALL TRADES</h5>
                                <p className='mb-3'>AND ENJOY CONSISTENT MONTHLY PASSIVE INCOME WITHOUT ANY HEADACHES! 💰</p>
                                <p>MIN STARTING AMOUNT 10$ ONLY ! <br />
                                    NO EXPERIENCE NEED ! <br />
                                    NO NEED TO MONITOR TRADES! <br />
                                    ANY HELP CONTACT- <a href='mailto:  mymoney1578@gmail.com'> mymoney1578@gmail.com</a>
                                </p>


                            </div>



                        </div>
                        <div className="col-md-4">
                            <img src={Business} class="card-img-top" alt="..." />


                        </div>
                    </div>
                </div>
            </div>
            <br /> */}
            <br />
       
        </div>
        </div>
    )
}

export default Bmodel