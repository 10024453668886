








import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';

import DropdownButton from 'react-bootstrap/DropdownButton'
import './Header.css'
// import { Button,Dropdown} from 'react-bootstrap';
import { Button, Navbar, Dropdown, Container, Nav, Form, FormControl, NavDropdown } from 'react-bootstrap';
import { useUserAuth } from '../../contexts/UserAuthContext'

import Spinner from 'react-bootstrap/Spinner';
import Logo from "../images/safelogo.jpg";


function Header(props) {

  let time = new Date().toLocaleTimeString();
  const [ctime, setCtime] = useState(time);

  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    setCtime(time);
  };

  setInterval(UpdateTime, 1000);



  const navigate = useNavigate()
  const { user, logOut } = useUserAuth()
  const [photoURL, setPhotoURL] = useState()
  //const emailVerified = user.emailVerified;
  const handleLogOut = async () => {
    try {
      await logOut()
      window.location.reload()
      navigate("/")
    } catch (err) {

    }
  }

  useEffect(() => {
    if (user?.photoURL) {
      setPhotoURL(user.photoURL)
    }

  }, [user])


  return (
    <>
      {/* <div className="mains container-fluid"> */}
      <div className="fixed-top">
        <div className="mrmondalanalytics">
          <div className="album py-2">

            <div className="container">

              <div className="row ">

                <div className="col-md-5" >
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <h4 style={{ color: "Darkblue" }} > <b> {ctime} </b></h4> */}

                    <Link to="/head">
                      <button style={{ pading: "1px" }} className="buttonbook"> <small>Marketing Partners</small></button>
                    </Link>


                    <Link to="/Protraders">
                      <button style={{ pading: "2px" }} className="buttonbook"> <small>Our Pro Trader</small></button>
                    </Link>

                    <Link to="/JOIN-FREE-TRAINING">
                      <button style={{ pading: "2px" }} className="buttonbook"> <small>Join Free Training</small></button>
                    </Link>

                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">





                </div>
              </div>
            </div>
          </div>
        </div>

        <Navbar bg="white shadow" expand="lg">
          <Container fluid>
            <Navbar.Brand>
              <Link to="/" className='me-0'>
                <img src={Logo} height="40px" width="75px" alt="" />
              </Link>

            </Navbar.Brand>
            <div className="text-end d-grid gap-2 d-md-flex justify-content-md-end">

              {!user ? (

                <div>

                  <Link to="/Login">
                    <button type="button" className="buttonlogin">LOGIN <Spinner animation="grow" variant="light" size="sm" /></button>
                  </Link>

                </div>
              ) :
                <>

                  <Dropdown className='dropdown'>
                    <Dropdown.Toggle variant="none" id="dropdown-basic">
                      <img className='userphoto' src={user.photoURL} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <center>
                        <Dropdown.Item>
                          <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='info btn-sm'><small>Dashboard</small></Button>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='primary btn-sm'>Chart</Button>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='success btn-sm'>Upload </Button>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Button variant='danger btn-sm' onClick={handleLogOut}><small>LOG OUT</small></Button>
                        </Dropdown.Item>
                      </center>
                    </Dropdown.Menu>

                  </Dropdown>
                </>

              }

            </div>

            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '290px' }}
                navbarScroll
              >

                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page" ><small>Home</small></Link>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small>Live Chart</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/tchart" className="dropdown-item" >Forex Chart</Link></li>
                    <li><Link to="/Multiplechart" className="dropdown-item" href="#">Multiple Forex Chart</Link></li>
                    <li><Link to="/cryptochart" className="dropdown-item" >Cryptocurrency Chart</Link></li>
                    <li>
                      <hr className="dropdown-divider" /></li>

                    <li><Link to="/indiceschart" className="dropdown-item" >Indices Chart</Link></li>
                    <li><Link to="/Goldchart" className="dropdown-item" >Commodities Chart</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small>Brokers</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/bestForexbrokers" className="dropdown-item" >Best Forex Brokers</Link></li>



                  </ul>
                </li>






                <li className="nav-item">
                  <Link to="/ecalendar" className="nav-link" aria-current="page" ><small>Economic Calendar</small></Link>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small>Live Technical Analysis</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/euranalysis" className="dropdown-item" >EUR/USD Analysis</Link></li>
                    <li><Link to="/Gbpanalysis" className="dropdown-item" >GBP/USD Analysis</Link></li>
                    <li><Link to="/goldanalysis" className="dropdown-item" >GOLD Analysis</Link></li>
                    <li><Link to="/chfanalysis" className="dropdown-item" >EUR/CHF Analysis</Link></li>
                    {/* <li><Link to="/jpyanalysis" className="dropdown-item" >USD/JPY Analysis</Link></li> */}


                  </ul>
                </li>


                <div class="dropdown">
                  <button className="dropbtn">Services <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg></button>
                  <div class="dropdown-content">

                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Free Services
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><Link to="/Fundmanagerjob" className="dropdown-item" >Fund Manager Job (Free)</Link></li>
                        <li><Link to="/book" className="dropdown-item" > Book (Free)</Link></li>


                      </ul>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Premium Services
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><Link to="/Signals" className="dropdown-item" > Signals (Premium)</Link></li>
                        <li><Link to="/Pindicator" className="dropdown-item" > Indicator (Premium)</Link></li>
                        <li><Link to="/CoursePremium" className="dropdown-item" > Course (Premium)</Link></li>

                      </ul>
                    </li>
                  </div>
                </div>



                <li className="nav-item">
                  <Link to="/Businessmodel" className="nav-link" aria-current="page" ><small>Business Model</small></Link>
                </li>

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small>Others</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {/* <li><Link to="/tchart" className="dropdown-item" >Our Busines Model</Link></li> */}
                    <li><Link to="/Head" className="dropdown-item" href="#">Marketing Partners</Link></li>

                    <li><Link to="/Mission" className="dropdown-item" href="#">Mission </Link></li>
                    {/* <li><Link to="/Businessmodel" className="dropdown-item" href="#">Our Busines Model</Link></li> */}
                    <li><Link to="/" className="dropdown-item" href="#">Be A Part Of Us</Link></li>

                    <li>
                      <hr className="dropdown-divider" /></li>
                    <li><Link to="/Disclaimer" className="dropdown-item" href="#">Disclamer</Link></li>
                    <li><Link to="/" className="dropdown-item" href="#">FAQ</Link></li>



                  </ul>
                </li>

                <li className="nav-item">
                  <Link to="/Performance" className="nav-link" aria-current="page" ><small>Performance</small></Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small>Our Performance</small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/Per2022" className="dropdown-item" >Performance 2022</Link></li>
                    <li><Link to="/Per2023" className="dropdown-item" >Performance 2023</Link></li>
                    <li><Link to="/Per2024" className="dropdown-item" >Performance 2024</Link></li>


                  </ul>
                </li> */}


                <li className="nav-item">
                  <Link to="/support" className="nav-link" aria-current="page" ><small>Support</small></Link>
                </li>







              </Nav>

            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

    </>

  );
}

export default Header;

