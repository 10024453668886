import React, { useState } from "react";

import { Form, Alert, InputGroup, Button, ButtonGroup, Card } from "react-bootstrap";
import BookDataService from "../services/book.support";


const AddSupport = () => {
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState("");
    const [enquiry, setEnquiry] = useState("");
    const [contact, setContact] = useState("");
    const [subject, setSubject] = useState("");
    const [status, setStatus] = useState("Available");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });



    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (title === "" || contact === "" || subject === "" || email === "" || enquiry === "") {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            title,
            enquiry,
            email,
            contact,
            subject,
            status
        }
        console.log(newBook);

        try {
            await BookDataService.addBooks(newBook);
            setMassage({ error: false, msg: "Your query has been submitted successfully. We will get back to you shortly." });
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setTitle("");
        setContact("");
        setSubject("");
        setEmail("");
        setEnquiry("");
    };
    return (
        <>



            <div className="album py-1">
                <div className="container">

                    <div className="row ">
                        <div className="col-md-8">
                            <Card body shadow>
                                <center>
                                    <h4 className="mb-3" style={{ color: "Gray" }}>SAFE FOREX RETURN
                                    </h4>
                                </center>
                                <div className="card mb-4 box-shadow">


                                    {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                                        dismissible
                                        onClose={() => setMassage("")}
                                    >
                                        {""}
                                        {massage?.msg}
                                    </Alert>
                                    )}

                                    <Form onSubmit={handleSubmit} >
                                        <Form.Group className="mb-3" controlId="formBookTitle">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookTitle"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Enter Your Name"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBookEmail">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookEmail"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-paper-fill" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M6.5 9.5 3 7.5v-6A1.5 1.5 0 0 1 4.5 0h7A1.5 1.5 0 0 1 13 1.5v6l-3.5 2L8 8.75l-1.5.75ZM1.059 3.635 2 3.133v3.753L0 5.713V5.4a2 2 0 0 1 1.059-1.765ZM16 5.713l-2 1.173V3.133l.941.502A2 2 0 0 1 16 5.4v.313Zm0 1.16-5.693 3.337L16 13.372v-6.5Zm-8 3.199 7.941 4.412A2 2 0 0 1 14 16H2a2 2 0 0 1-1.941-1.516L8 10.072Zm-8 3.3 5.693-3.162L0 6.873v6.5Z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Enter Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBookContact">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookContact"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-plus-fill" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Enter Contact Number"
                                                    value={contact}
                                                    onChange={(e) => setContact(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBookSubject">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookSubject">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
                                                        <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                                    </svg>
                                                </InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Enter Subject"
                                                    value={subject}
                                                    onChange={(e) => setSubject(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBookEnquiry">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookEnquiry"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-text" viewBox="0 0 16 16">
                                                    <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z" />
                                                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control as="textarea" row={3}
                                                    placeholder="Message"
                                                    value={enquiry}
                                                    onChange={(e) => setEnquiry(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        {/* <ButtonGroup aria-lable="Basic example" className="mb-3">
                    <Button 
                    disabled={flag} variant="success" 
                    onClick={(e) => {
                    setStatus("Available");
                    setFlag(true);
                    }}
                    >
                        Available
                        </Button>
                        <Button variant="danger" 
                        onClick={(e) => {
                        setStatus("Not Available");
                        setFlag(false);
                        }}
                        >Not Available</Button>
        
                </ButtonGroup> */}

                                        <div className="d-grid gap-2">
                                            <Button variant="outline-info" type="Submit">
                                                Submit
                                            </Button>
                                        </div>


                                    </Form>


                                </div>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header mb-3">Contact Us</div>
                                <div className="card-body">
                                    <h5 className="card-title mb-3">OUR OFFICIAL MAIL </h5>




                                    <a className="mb-2" href="mailto: support@safeforexreturn.com"> SUPPORT:  support@safeforexreturn.com</a>
                                    <br />
                                    <br />
                                    <a href="https://t.me/onlinemoney99" target="_blank" className="buttontele mb-3">Our Telegram channel link <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                                    </svg></a>

                                    <a href="https://www.youtube.com/channel/UCahvBjHPLhJq9faTtp-Oc_g" target="_blank" className="btn btn-outline-danger mb-3">Our YouTube channel link <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                                    </svg></a>
                                    <a href="https://www.facebook.com/profile.php?id=61552846388048" target="_blank" className="btn btn-primary mb-3">Our Facebook channel link <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>


    )
}
export default AddSupport;
