// // TradingViewWidget.jsx

// import React, { useEffect, useRef } from 'react';
// import Header from '../inc/Header';

// let tvScriptLoadingPromise;

// export default function TradingViewWidget() {
//   const onLoadScriptRef = useRef();

//   useEffect(
//     () => {
//       onLoadScriptRef.current = createWidget;

//       if (!tvScriptLoadingPromise) {
//         tvScriptLoadingPromise = new Promise((resolve) => {
//           const script = document.createElement('script');
//           script.id = 'tradingview-widget-loading-script';
//           script.src = 'https://s3.tradingview.com/tv.js';
//           script.type = 'text/javascript';
//           script.onload = resolve;

//           document.head.appendChild(script);
//         });
//       }

//       tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

//       return () => onLoadScriptRef.current = null;

//       function createWidget() {
//         if (document.getElementById('tradingview_c17a8') && 'TradingView' in window) {
//           new window.TradingView.widget({

//             symbol: "FX:EURUSD",
//             interval: "D",
//             timezone: "Etc/UTC",
//             theme: "light",
//             style: "0",
//             locale: "in",
//             enable_publishing: false,
//             hide_top_toolbar: true,
//             hide_legend: true,
//             save_image: false,
//             details: true,
//             hide_volume: true,
//             container_id: "tradingview_c17a8"
//           });
//         }
//       }
//     },
//     []
//   );

//   return (
//     <div>
//       <Header/>
//       <br/>
//       <br/>
//       <br/>

//     <div className="album py-3">

//       <div className="container">


//         <div className="row">
//         <div className="col-md-3">
//             <h1>kbcvjkczbx vj b</h1>
//           </div>
//           <div className="col-md-5">

//             <div className='tradingview-widget-container' >
//               <div id='tradingview_c17a8'  />
//               <div className="tradingview-widget-copyright">


//               </div>
//             </div>
//           </div>

//         </div>
//         </div>
//       </div>
//     </div>
//   );
// }




import React from 'react'
import Header from '../inc/Header'
import Analysispage1 from './Analysispage1'
import ScrollToTop from "react-scroll-to-top";
function Euranalysis() {
  return (
    <div>

      <Header />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="maroon" />

      <div className="album py-4">

        <div className="container">

          <div className="row">
            <div className="col-md-8" >
              <div class="card mb-5" >
                <div class="card-header"><center>CATEGORY ARCHIVES: EUR/USD ANALYSIS</center></div>
                <Analysispage1 />
                <div class="col-md-4">

                </div>


              </div>
            </div>
            <div className="col-md-4" >

              <div className="card mb-4 border-light box light">


                <iframe className="iframe-fluid" height="610" id="gmap_canvas" src="https://safeforexreturn.netlify.app/analysis.html" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>





              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Euranalysis



