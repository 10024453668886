import React, { useEffect, useRef } from 'react';
import Header from '../inc/Header'
import Eurusd from "../pages/Eurusd";
import Usdjpy from "../pages/Usdjpy";

let tvScriptLoadingPromise;

export default function Multiplechart() {

    const onLoadScriptRef = useRef();
    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_4fb71') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        autosize: true,
                        symbol: "EURUSD",
                        timezone: "Etc/UTC",
                        theme: "light",
                        style: "1",
                        locale: "in",
                        enable_publishing: true,
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        gridColor: "rgba(238, 238, 238, 0.06)",
                        withdateranges: true,
                        range: "YTD",
                        hide_side_toolbar: false,
                        allow_symbol_change: true,
                        details: true,
                        hotlist: true,
                        calendar: true,
                        show_popup_button: true,
                        popup_width: "1000",
                        popup_height: "650",
                        container_id: "tradingview_4fb71"
                    });
                }
            }
        },
        []
    );



    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_89456') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        autosize: true,
                        symbol: "GBPUSD",
                        interval: "D",
                        timezone: "America/Juneau",
                        theme: "light",
                        style: "2",
                        locale: "in",
                        enable_publishing: false,
                        backgroundColor: "rgba(66, 66, 66, 1)",
                        withdateranges: true,
                        hide_side_toolbar: false,
                        allow_symbol_change: true,
                        details: true,
                        calendar: true,
                        container_id: "tradingview_89456"
                    });
                }
            }
        },
        []
    );

    return (
        <div>
            <Header />

            <br />
            <br />
            <br />
            <br/>
      <br/>
            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        <h2 style={{ color: "grey" }} ><small>Multiple Forex Charts</small></h2>
                        <p><b>Multiple Forex charts</b> provide real-time currency prices updated every second without refreshing the page. On our real-time charts, you can use various tools to analyze your chosen currency pair or instrument, change the time period, and analyze the market using a number of technical indicators.
                        </p>
                    </div>
                </div>
            </div>

            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <Eurusd />
                        </div>

                        <div className="col-md-4">
                            <div className='tradingview-widget-container' style={{ height: "420px", width: "100%" }}>
                                <div id='tradingview_89456' style={{ height: "calc(100% - 32px)", width: "100%" }} />
                                <div className="tradingview-widget-copyright">
                                    <a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <Usdjpy/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

