
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyD7dcop87UNeQaj3WPaF21aVuY11Lhjz3I",
    authDomain: "safe-forex-return.firebaseapp.com",
    projectId: "safe-forex-return",
    storageBucket: "safe-forex-return.appspot.com",
    messagingSenderId: "657240336129",
    appId: "1:657240336129:web:acb981d1bb83ab62039d00",
    measurementId: "G-6M49BNB4VH"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;