import React from 'react'
import Notice from "../pages/Notice";
// import fundmanage from "../images/fund.jpg";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import Slide from '../inc/Slide';
import Currentr from './Currentr';

function Noticepage() {
    return (
        <div>
            <div className="album py-3">

                <div className="container">


                    <div className="row">
                        <div className="col-md-5">
                            <div className="card" >
                                <Link to="/managefund" >
                                    <Slide />
                                </Link>
                                <div className="card-body">
                                    <h5 style={{ color: "darkblue" }} className="card-title">FUND MANAGEMENT SERVICE</h5>
                                    <Link to="/managefund" >
                                        <button className="buttonfund">Fund Management Services (Premium) <Spinner animation="grow" variant="light" size="sm" /></button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3" >
        <div className="card" >
        <div style={{ color: "Darkblue" }} className="card-header"><center>CURRENT RESULTS</center></div>
                        
                            <Currentr/>
                             
                               </div>
                               </div>
                            
                     
                        <div className="col-md-4">

                            <div className="card" >
                                <div style={{ color: "Darkblue" }} className="card-header"><center>Notice Board</center></div>

                                <div className="card-body">
                                    <Notice />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Noticepage