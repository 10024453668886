import React from 'react'
import Blueberry from "../images/blueberry.jpg";
import Xness from "../images/xness.jpg";
import {Link} from "react-router-dom";


function Slider() {
  return (
    <div>

<div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
    <Link to="https://secure.blueberrymarkets.com/en/auth/create-account?refer=12668MT4-BB-L" target="_blank">
      <img src={Blueberry} className="d-block w-100" alt="blueberry_with_SAFEFOREXRETURN"/>
      </Link>
    </div>
    <div className="carousel-item">
    <Link to="https://www.exness.com/?utm_source=partners&_8f4x=1" target="_blank">
      <img src={Xness} className="d-block w-100" alt="exness_with_safe_forex-return"/>
      </Link>
    </div>
  
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

    </div>
  )
}

export default Slider