import React from 'react'
import Header from '../inc/Header'
import Tickmailads from "../images/tickmailads.png";
import exness from "../images/Crypto.gif";
import AddSignals from "../AddSignals";
import book from "../images/book.jpeg";
import forex from "../images/forex_trading_tutorial.pdf";
import bible from "../images/TheCandlestickTradingBible.pdf";
import FOREXTRADING from "../images/FOREXTRADING1.pdf";
import FOREXFACTORY from "../images/Candlesticks.pdf";

function Book() {
  return (
    <div>
     <Header/>
     <br />
            <br />
            <br />
            <br/>
            <br />
            <br />
<center><h4><b>BOOK</b></h4></center>
            <div className="album py-3">
                <div className="container">

                        <div className="card">
                        <div className="row">
                            <div className="col-md-9">
                        
                                <div className="card-body">
                            <img src={book} className="card-img-top mb-3" alt=""/>

                            <center>
                                <button  className='buttonbook mb-3'>FREE BOOK DOWNLOAD</button>
                            </center>



                            <br/>
                            <a href={forex} target="_blank" rel="noopener noreferrer" className='btn btn-warning me-3 mb-3'>FOREX TRADING TUTORIAL <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z"/>
</svg></a>
<a href={bible} target="_blank" rel="noopener noreferrer" className='btn btn-warning mb-3 me-3'> THE CANDLESTICK TRADING BIBLE  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z"/>
</svg></a>

<a href={FOREXTRADING} target="_blank" rel="noopener noreferrer" className='btn btn-warning mb-3 me-3'> FOREX TRADING THE BASICS EXPLAINED IN SIMPLE TERMS  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z"/>
</svg></a>
<a href={FOREXFACTORY} target="_blank" rel="noopener noreferrer" className='btn btn-warning mb-3 me-3'> CANDLESTICKS, FIBONACCI, AND CHART PATTERN TRADING - FOREX FACTORY<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z"/>
</svg></a>

                                   

          
                                </div>
                            </div>

                            <div className="col-md-3">
                            <div class="card border-dark mb-3" >
                              <a href='https://www.tickmill.com/promotions/welcome-account/?utm_campaign=ib_link&utm_content=IB52863714&utm_medium=%2430+Welcome+Account-%284%29&utm_source=link&utm_term=&referrer=' target='_blank'>
                            <img src={Tickmailads} className="card-img-top" alt="..."/>
                            </a>
                            <a href='https://www.exness.com/?utm_source=partners&_8f4x=1' target='_blank'>
                            <img src={exness} alt="exness"/>
                            </a>


</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Book