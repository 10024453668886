// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';
import Header from '../inc/Header';

let tvScriptLoadingPromise;

export default function Cryptochart() {
  const onLoadScriptRef = useRef();

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_bbc66') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: "BINANCE:BTCUSDT",
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: "in",
            
            enable_publishing: true,
            backgroundColor: "rgba(66, 66, 66, 1)",
            withdateranges: true,
            range: "YTD",
            hide_side_toolbar: false,
            allow_symbol_change: true,
            details: true,
            hotlist: true,
            calendar: true,
            show_popup_button: true,
            popup_width: "1000",
            popup_height: "650",
            container_id: "tradingview_bbc66"
          });
        }
      }
    },
    []
  );

  return (
    <>
    <Header/>
    <br/>
    <br/>
    <br/>
      <br/>
    <br/>
    
    <div className="album py-2">

<div className="container">

  <div className="row">
    <h3><small>Bitcoin Price Live</small></h3>
    <p><small>Bitcoin Price Live Chart provides real-time Bitcoin prices updated every second without having to refresh the web page. In our Bitcoin price chart, you can use a variety of tools to get a full picture of the crypto market.
</small></p>
  </div>
</div>
</div>

    <div className='tradingview-widget-container' style={{ height: "900px", width: "100%" }}>
      <div id='tradingview_bbc66' style={{ height: "calc(100% - 32px)", width: "100%" }} />
      <div className="tradingview-widget-copyright">
        <a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
      </div>
    </div>
    </>
  );
}
