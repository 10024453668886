import React, { useState } from "react";

import { Form, Alert, InputGroup, Button, ButtonGroup, Card } from "react-bootstrap";
import BookDataService from "../services/book.signals";


const AddSupport = () => {
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState("");

    const [country, setCountry] = useState("");
    const [subject, setSubject] = useState("");
    const [status, setStatus] = useState("Available");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });



    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (title === "" || email === "" || country === "" || subject === "") {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            title,

            email,

            country,
            subject,
            status
        }
        console.log(newBook);

        try {
            await BookDataService.addBooks(newBook);
            setMassage({ error: false, msg: "Your query has been submitted successfully. We will get back to you shortly." });
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setTitle("");

        setCountry("");
        setSubject("");
        setEmail("");

    };
    return (
        <>



            <div className="album py-1">
                <div className="container">

                    <div className="row ">
                        <div className="col-md-10">
                            <Card body shadow>
                                <center>
                                    <h4 className="mb-3" style={{ color: "Gray" }}>SAFE FOREX RETURN
                                    </h4>
                                </center>
                                <div className="card mb-4 box-shadow">


                                    {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                                        dismissible
                                        onClose={() => setMassage("")}
                                    >
                                        {""}
                                        {massage?.msg}
                                    </Alert>
                                    )}

                                    <Form onSubmit={handleSubmit} >
                                        <Form.Group className="mb-3" controlId="formBookTitle">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookTitle"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Enter Your Name"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBookEmail">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookEmail"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-paper-fill" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M6.5 9.5 3 7.5v-6A1.5 1.5 0 0 1 4.5 0h7A1.5 1.5 0 0 1 13 1.5v6l-3.5 2L8 8.75l-1.5.75ZM1.059 3.635 2 3.133v3.753L0 5.713V5.4a2 2 0 0 1 1.059-1.765ZM16 5.713l-2 1.173V3.133l.941.502A2 2 0 0 1 16 5.4v.313Zm0 1.16-5.693 3.337L16 13.372v-6.5Zm-8 3.199 7.941 4.412A2 2 0 0 1 14 16H2a2 2 0 0 1-1.941-1.516L8 10.072Zm-8 3.3 5.693-3.162L0 6.873v6.5Z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Enter Email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBookCountry">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookCountry"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe-central-south-asia" viewBox="0 0 16 16">
                                                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM4.882 1.731a.482.482 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.721.721 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a.996.996 0 0 0-.462-.04 7.03 7.03 0 0 1 2.45-2.027Zm-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.78.78 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.454.454 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282a.61.61 0 0 0 .04-.001 7.003 7.003 0 0 1-12.658.905Z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Enter Country Name"
                                                    value={country}
                                                    onChange={(e) => setCountry(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBookSubject">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookSubject">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book-half" viewBox="0 0 16 16">
                                                        <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                                    </svg>
                                                </InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Monthly-99$ / Yearly -299$ / Lifetime -499$"
                                                    value={subject}
                                                    onChange={(e) => setSubject(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                            
                                       





                                        {/* <ButtonGroup aria-lable="Basic example" className="mb-3">
                    <Button 
                    disabled={flag} variant="success" 
                    onClick={(e) => {
                    setStatus("Available");
                    setFlag(true);
                    }}
                    >
                        Available
                        </Button>
                        <Button variant="danger" 
                        onClick={(e) => {
                        setStatus("Not Available");
                        setFlag(false);
                        }}
                        >Not Available</Button>
        
                </ButtonGroup> */}

                                        <div className="d-grid gap-2">
                                            <Button variant="outline-info" type="Submit">
                                                Submit
                                            </Button>
                                        </div>


                                    </Form>


                                </div>
                            </Card>
                        </div>


                    </div>
                </div>
            </div>



        </>


    )
}
export default AddSupport;
