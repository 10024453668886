import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'



function Currentr() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
     const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [currentresult, setCurrentresult] = useState([])
      const navigate = useNavigate()
  
      const currentresultCollectionRef = collection(db, "Currentresult");
      useEffect(() => {
  
          const getCurrentresult = async () => {
              const data = await getDocs(currentresultCollectionRef);
              setCurrentresult(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getCurrentresult()
      }, [])
    return (
      
<>







            {currentresult.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((safeforexreturn) => { return (
       

                            <div className="card-body" key={safeforexreturn.id}>
                                <img key={safeforexreturn.id} src={safeforexreturn.img} className="card-img-top mb-2" alt=""/>

                           
                            
                              


                                <a href={safeforexreturn.link} target='_black'>{safeforexreturn.text}</a>

                        
                                {/* <h5 style={{color: "Darkblue"}}className="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={currentresult.length}
                /> */}
                           

</>
        
        );
    }
    
    export default Currentr;