import React from 'react'
import Mainsafeforex from "../images/SAFE-update.jpg";
import Mrmondalanayalticexpert from "../images/sliders2.jpg";
function Sliderm() {
  return (
    <div>
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Mainsafeforex} className="d-block w-100" alt="safeforexreturn"/>
    </div>
    <div className="carousel-item">
      <img src={Mrmondalanayalticexpert} className="d-block w-100" alt="safeforexreturninindia"/>
    </div>
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Sliderm