import React from 'react'
import Header from '../inc/Header'

function Ecalendar() {
  return (
    <div>
      <Header/>
      <br/>
      <br/>
    
      <br/>
   <br/>
   
      <div className="album py-3">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-12" >
          
                        <div className="card mb-4 border-light box light">
                                
                                
              <iframe  className="iframe-fluid"  height="910"  id="gmap_canvas" src="https://safeforexreturn.netlify.app/calendar.html" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>

                     
                        
                       
                            
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
    </div>
  )
}

export default Ecalendar