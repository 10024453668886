



// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';
import Header from '../inc/Header';
let tvScriptLoadingPromise;

export default function TradingViewWidget() {
  const onLoadScriptRef = useRef();

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_4fb71') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: "EURUSD",
            timezone: "Etc/UTC",
            theme: "light",
            style: "1",
            locale: "in",
            enable_publishing: true,
            backgroundColor: "rgba(255, 255, 255, 1)",
            gridColor: "rgba(238, 238, 238, 0.06)",
            withdateranges: true,
            range: "YTD",
            hide_side_toolbar: false,
            allow_symbol_change: true,
            details: true,
            hotlist: true,
            calendar: true,
            show_popup_button: true,
            popup_width: "1000",
            popup_height: "650",
            container_id: "tradingview_4fb71"
          });
        }
      }
    },
    []
  );

  return (
    <>
      <Header />
      <br />
      <br />
      <br />
      <br/>
      <br/>
   

      <div className="album py-2">

        <div className="container">

          <div className="row">
            <h2 style={{ color: "grey" }} ><small>FOREX LIVE CHART</small></h2>
            <h4><small>EUR USD Live Chart</small></h4>
            <p><small>The EUR USD Live chart provides real-time prices updated every second without refreshing the page. In our EUR/USD real-time charts, various tools can be used to fully understand the currency market.
            </small></p>
          </div>
        </div>
      </div>
      <div className="album py-3">
        <div className="container">
          <div className='tradingview-widget-container' style={{ height: "680PX", width: "100%" }}>
            <div id='tradingview_4fb71' style={{ height: "calc(100% - 32px)", width: "100%" }} />
            <div className="tradingview-widget-copyright">
              <a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}
