import React from 'react'
import Header from '../inc/Header'
import Tickmailads from "../images/tickmailads.png";
import exness from "../images/Crypto.gif";
import AddSignals from "../AddSignals";

function Signals() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br/>

            <div className="album py-3">

                <div className="container">


                
                        <div className="card">
                        <div className="row">
                            <div className="col-md-9">
                          
                                <div className="card-body">
                                    <h5 className="card-title">Join our premium signal group and enjoy consistent profit!</h5>
                                    <h5 className="card-text">Rules –</h5>
                                    <p>Congratulation on joining the SAFE FOREX team. To make your forex journey smoother and ensure highest profitability we high recommend to follow these guidelines strictly.</p>
                                    <p className='mb-4'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg> Account Balance Below $300</p>
<p>If SL below 40 PIPS take trade with 0.02 lot size. <br/>
If SL above 40 Pips take trade with 0.01 lot size.</p>
<p className='mb-4'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg>  Account Balance Between $300-$600</p>
<p>If SL below40 Pips take trade with 0.03 lot size. <br/>
If SL above 40 Pips take trade with 0.02 lot size.</p>
<p className='mb-4'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg>  Account Balance Between $600-$1000</p>
<p>If SL below 40Pips take trade with 0.05 lot size. <br/>
If SL above 30 Pips take trade with 0.03 lot size.</p>
<p className='mb-4'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg>Account Balance Between $1000-$1500</p>
<p>
If SL below 30Pips take tradeYou with 0.07 lot size. <br/>
If SL above 30 Pips take trade with 0.04 lot size.
</p>
<p>Like that lot sizes changed according to account balance! <br/>
SL should not be more than 1% per trade !</p>
<p className='mb-3'>Once you get update SL to Entry, always move your SL to Entry price.</p>
<p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg> Let say for EURUSD your entry price was 1.1850 (Buy order) and SL was 1.1820, once you get update move SL to Entry you must change your SL from 1.1820 to
1.1850 (which is your entry price).</p>
<p className='mb-3'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg> Always add spread with SL and TP</p>
<p>DO NOT ENTER OLD TRADES, WAIT FOR NEW SIGNAL</p>

           <AddSignals/>
                                </div>
                            </div>

                            <div className="col-md-3">
                            <div class="card border-dark mb-3" >
                            <img src={Tickmailads} className="card-img-top" alt="..."/>
                            <img src={exness} alt="exness"/>


</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signals