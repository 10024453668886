import React from 'react'
import Mondaltrading from "../images/slide3.jpg";
import besttradingplatform from "../images/slide5.jpg";
import forexreturn from "../images/slide4.jpg";
import Safeforex from "../images/slide2-update.jpg";
import fundmanage from "../images/fund.jpg";

function Slide() {
  return (
    <div>
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Mondaltrading} className="d-block w-100" alt="safe-forex-retutn"/>
    </div>
    <div className="carousel-item">
      <img src={Safeforex} className="d-block w-100" alt="safeforexretutn"/>
    </div>
    <div className="carousel-item">
      <img src={forexreturn} className="d-block w-100" alt="safe_forex_retutn"/>
    </div>
    <div className="carousel-item">
      <img src={besttradingplatform} className="d-block w-100" alt="safe-forex-retutn"/>
    </div>
    <div className="carousel-item">
      <img src={fundmanage} className="d-block w-100" alt="safeforexretutn"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slide