import React from 'react'
import Header from "../inc/Header";
import AddOnline from '../AddOnline';

function Online() {
  return (
    <div>
        <Header/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <center><h2><b>JOIN FREE TRAINING</b></h2></center>

        <AddOnline/>

    </div>
  )
}

export default Online