
import React from 'react'
import Header from '../inc/Header'
import Sliderimage from '../inc/Slider'
import Icmarkets from "../images/ic-markets.jpg";
import exness from "../images/card2.jpg";
import Fpmarkets from "../images/fp-markets.jpg";
import { Link } from "react-router-dom";
import Welcometrade from "../images/Welcome.png";
import Analysispage1 from './Analysispage1';
import Gbpanalysis from './Gbpanalysis';
import Tickmailads from "../images/tickmailads.png";
import Welcometrade1 from "../images/Crypto.gif";
import ReactWhatsapp from "react-whatsapp";
import Noticepage from './Noticepage';
import Sliderm from '../inc/Sliderm';
import ReactPlayer from "react-player";
function Main() {
  return (
    <div>
      <Header />
      <br />
      <br />
  
      <div style={{ margin: "10px" }} className="fixed-bottom ">
        <div className="d-flex justify-content-end">


          <ReactWhatsapp number="+91  9163542888" className="btn btn-success" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
          </svg>  Live Whats App Chat <div class="spinner-grow text-light spinner-grow-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div></ReactWhatsapp>

        </div>
      </div>
      <div className="album py-2">

        <div className="container">

          <div className="row">
            <h5><small>Safe Forex rfeturn</small></h5>
          </div>
        </div>
      </div>


    
      <Sliderm />

      <br />
      <Noticepage />

      <div className="album py-4">
        <div className="container">
          <div className="row ">
            <ReactPlayer width="100%" height="340px" url="https://www.youtube.com/watch?v=KlatEh9EbLc" />
          </div>
        </div>
      </div>


      <h3 style={{ color: "Darkblue" }}><b><center>TRADE WITH TOP REGULATED BROKERS</center></b></h3>
      <div className="album py-4">

        <div className="container">

          <div className="row">
            <div className="col-md-4">
              <div className="card" >
                <Link to="https://www.icmarkets.com/?camp=35526" target="_blank">

                  <img src={Icmarkets} class="card-img-top" alt="blueberrymarket" /></Link>
                <div className="card-body">
                  <center>
                    <Link to="https://www.icmarkets.com/?camp=35526" target="_blank">
                      <button className="buttonhome">Learn More</button>
                    </Link>
                  </center>
                </div>
              </div>

            </div>
            <div className="col-md-4">
              <div className="card" >
                <a href="https://www.exness.com/?utm_source=partners&_8f4x=1" target="_blank">
                  <img src={exness} class="card-img-top" alt="blueberrymarket" /></a>
                <div className="card-body">
                  <center>
                    <Link to="https://www.exness.com/?utm_source=partners&_8f4x=1" target="_blank">
                      <button className="buttonhome">Learn More</button>
                    </Link>
                  </center>
                </div>
              </div>

            </div>
            <div className="col-md-4">
              <div className="card" >
                <Link to="https://www.fpmarkets.com/?redir=stv&fpm-affiliate-utm-source=IB&fpm-affiliate-agt=24376" target="_blank">
                  <img src={Fpmarkets} class="card-img-top" alt="blueberrymarket" />
                </Link>
                <div className="card-body">
                  <center>
                    <Link to="https://www.fpmarkets.com/?redir=stv&fpm-affiliate-utm-source=IB&fpm-affiliate-agt=24376" target="_blank">
                      <button className="buttonhome">Learn More</button>
                    </Link>
                  </center>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>
      <br />
      <Sliderimage />
      <div className="album py-3">

        <div className="container">


          <div className="row ">

            <div className="col-md-9">


              <Analysispage1 />
              <Gbpanalysis />


            </div>
            <div className="col-md-3">
              <div class="card border-dark mb-3" >
                <a href='https://www.tickmill.com/promotions/welcome-account/?utm_campaign=ib_link&utm_content=IB52863714&utm_medium=%2430+Welcome+Account-%284%29&utm_source=link&utm_term=&referrer=' target='_blank'>
                  <img src={Tickmailads} className="card-img-top" alt="tickmail" />
                </a>

                {/* <a href='https://www.exness.com/?utm_source=partners&_8f4x=1' > */}
                <img src={Welcometrade1} alt="exness" />
                {/* </a> */}


              </div>
            </div>
          </div>
        </div>
      </div>



      <br />
      <div className="album py-1">

        <div className="container">


          <div className="row">
            <center>
              <Link to="https://www.tickmill.com/" target="_blank">
                <img src={Welcometrade} className='img-fluid' alt="safeforexreturn" />
              </Link>
            </center>
          </div>
        </div>
      </div>
      <br />
      <br />

    </div>
  )
}

export default Main