import React from 'react'
import  Mrmmondal from "../images/mrmmondal.jpeg";
import Protraders from "../images/traders.jpeg";
function Proslider() {
  return (
    <div>


<div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Mrmmondal} className="d-block w-100" alt="MrMonaMondal"/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Mr. Mona Mondal</h5>
        <p>I  am a Professional forex fund manager!</p>
      </div>
    </div>

    <div className="carousel-item">
      <img src={Protraders} className="d-block w-100" alt="MrMonaMondal"/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Mr. Mona Mondal</h5>
        <p>I am a Professional forex fund manager!.</p>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Proslider