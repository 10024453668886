


import React from 'react'
import Header from '../inc/Header'
import Analysispage1 from './Analysispage1'
import ScrollToTop from "react-scroll-to-top";
import Gbpanalysis from './Gbpanalysis';
import Goldanalysis from './Goldanalysis';
function Euranalysis() {
  return (
    <div>

      <Header />
      <br />
      <br />
      <br />
            <br />
      <br />
      <ScrollToTop smooth top="100" color="maroon" />

      <div className="album py-4">

        <div className="container">

          <div className="row">
            <div className="col-md-8" >
              <div class="card mb-5" >
                <div class="card-header"><center>CATEGORY ARCHIVES: GOLD ANALYSIS</center></div>
                <Goldanalysis/>
                  <div class="col-md-4">
            
                  </div>
                
              
              </div>
            </div>
            <div className="col-md-4" >

              <div className="card mb-4 border-light box light">


                <iframe className="iframe-fluid" height="610" id="gmap_canvas" src="https://safeforexreturn.netlify.app/goldanalysis" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>





              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Euranalysis



