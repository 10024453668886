import React from 'react'
import Header from '../inc/Header'
import AddCourse from "../AddCourse";

function Course() {
  return (
    <div>
        <Header/>
        <br/>
        <br/>
        <br/>
        <br/>
      <br/>
        <br/>
        <center><h3>Price Action Scalping Course</h3></center>

        <br/>
        <AddCourse/>
    </div>
  )
}

export default Course