import React, { useState } from "react";

import { Form, Alert, InputGroup, Button, ButtonGroup, Card } from "react-bootstrap";
import BookDataService from "../services/book.manage";
import Forex from "../components/images/safeforex.jpg";
import Trading from "../components/images/forexreturn.jpeg";

const AddManage = () => {
    const [mt5mt4number, setMt5mt4number] = useState("");
    const [pasword, setPasword] = useState("");
    const [server, setServer] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [email, setEmail] = useState("");

    const [status, setStatus] = useState("Available");
    const [flag, setFlag] = useState(true);
    const [massage, setMassage] = useState({ error: false, msg: "" });



    const handleSubmit = async (e) => {
        e.preventDefault();
        setMassage("");
        if (mt5mt4number === "" || server === "" || pasword === "" || whatsapp ==="" || email === "") {
            setMassage({ error: true, msg: "All fields are mandatory!" });
            return;
        }
        const newBook = {
            mt5mt4number,
            whatsapp,
            email,
            pasword,
            server,
          
            status
        }
        console.log(newBook);

        try {
            await BookDataService.addBooks(newBook);
            setMassage({ error: false, msg: "Your query has been submitted successfully. We will get back to you shortly." });
        } catch (err) {
            setMassage({ error: true, msg: err.massage });
        }

        setMt5mt4number("");
        setServer("");
        setPasword("");
        setWhatsapp("");
        setEmail("");
        
    };
    return (
        <>



            <div className="album py-1">
                <div className="container">

                    <div className="row ">
                        <div className="col-md-8">
                            <Card body shadow>
                                <center>
                                    <h4 className="mb-3" style={{ color: "Gray" }}>ALREADY HAVE TRADING ACCOUNT
                                    </h4>
                                </center>
                                <div className="card mb-4 box-shadow">


                                    {massage?.msg && (<Alert variant={massage?.error ? "danger" : "success"}
                                        dismissible
                                        onClose={() => setMassage("")}
                                    >
                                        {""}
                                        {massage?.msg}
                                    </Alert>
                                    )}

                                    <Form onSubmit={handleSubmit} >
                                        <Form.Group className="mb-3" controlId="formBooMmt5mt4number">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookMt5mt4number"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="MT4/MT5 Number"
                                                    value={mt5mt4number}
                                                    onChange={(e) => setMt5mt4number(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBookPasword">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookPasword"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-paper-fill" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M6.5 9.5 3 7.5v-6A1.5 1.5 0 0 1 4.5 0h7A1.5 1.5 0 0 1 13 1.5v6l-3.5 2L8 8.75l-1.5.75ZM1.059 3.635 2 3.133v3.753L0 5.713V5.4a2 2 0 0 1 1.059-1.765ZM16 5.713l-2 1.173V3.133l.941.502A2 2 0 0 1 16 5.4v.313Zm0 1.16-5.693 3.337L16 13.372v-6.5Zm-8 3.199 7.941 4.412A2 2 0 0 1 14 16H2a2 2 0 0 1-1.941-1.516L8 10.072Zm-8 3.3 5.693-3.162L0 6.873v6.5Z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Enter Pasword"
                                                    value={pasword}
                                                    onChange={(e) => setPasword(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBookServer">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookServer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-plus-fill" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
                                                </svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Server"
                                                    value={server}
                                                    onChange={(e) => setServer(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBookWhatsapp">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookWhatsapp"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Whatsapp"
                                                    value={whatsapp}
                                                    onChange={(e) => setWhatsapp(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>


                                        
                                        <Form.Group className="mb-3" controlId="formBookEmail">
                                            <InputGroup>
                                                <InputGroup.Text id="formBookEmail"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-arrow-up-fill" viewBox="0 0 16 16">
  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697Zm.192 8.159 6.57-4.027L8 9.586l1.239-.757.367.225A4.49 4.49 0 0 0 8 12.5c0 .526.09 1.03.256 1.5H2a2 2 0 0 1-1.808-1.144ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z"/>
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.354 1.25 1.25a.5.5 0 0 1-.708.708L13 12.207V14a.5.5 0 0 1-1 0v-1.717l-.28.305a.5.5 0 0 1-.737-.676l1.149-1.25a.5.5 0 0 1 .722-.016Z"/>
</svg></InputGroup.Text>
                                                <Form.Control type="text"
                                                    placeholder="Email ID"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        {/* <ButtonGroup aria-lable="Basic example" className="mb-3">
                    <Button 
                    disabled={flag} variant="success" 
                    onClick={(e) => {
                    setStatus("Available");
                    setFlag(true);
                    }}
                    >
                        Available
                        </Button>
                        <Button variant="danger" 
                        onClick={(e) => {
                        setStatus("Not Available");
                        setFlag(false);
                        }}
                        >Not Available</Button>
        
                </ButtonGroup> */}

                                        <div className="d-grid gap-2">
                                            <Button variant="outline-info" type="Submit">
                                                Submit
                                            </Button>
                                        </div>


                                    </Form>


                                </div>
                               
                            </Card>
                            <br/>
                                <p className="mb-1">If you don’t have a trading account then join with Our regulated broker <a style={{color:"purple"}} href="www.safeforexreturn.com/best-forex-brokers/"><b>www.safeforexreturn.com/best-forex-brokers/</b></a>  and send us your MT4/MT5 details to</p>
                                <a href="mailto: support@safeforexreturn.com.">support@safeforexreturn.com.</a>
                                <p>Profit Share Every Month First Week Our Target Monthly 5% to 10% Safely.</p>
                        </div>

                        <div className="col-md-4">
                        <div className="card">
                               
                                <img src={Forex} alt="hiring" />
                                <div className="card-body ">



                                    {/* <img src={Trading} alt="hiring" /> */}







                                </div>
                            </div>
                            <br/>
                            <div className="card">
                                
                                <img src={Trading} alt="hiring" />
                                <div className="card-body">


                                    
                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>


    )
}
export default AddManage;
