import React from 'react'
import Header from '../inc/Header'
import AddSupport from '../AddSupport'

function Supportpage() {
  return (
    <div>
            <Header/>

            <br/>
            <br/>
            <br/>
            <br/>
            <br />
            <br />
            <center style={{color:"Darkblue"}} ><h2 className='mb-3'><b>SUPPORT</b></h2></center>
            <AddSupport/>
            <br/>

    </div>
  )
}

export default Supportpage