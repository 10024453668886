// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';
import Header from '../inc/Header';
import Goldlivechart from "../images/Gold-Live-Chart.jpg";

let tvScriptLoadingPromise;

export default function TradingViewWidget() {
    const onLoadScriptRef = useRef();

    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview_51c4a') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        autosize: true,
                        symbol: "GOLD",
                        timezone: "America/Juneau",
                        theme: "light",
                        style: "2",
                        locale: "in",
                        enable_publishing: true,
                        backgroundColor: "rgba(66, 66, 66, 1)",
                        gridColor: "rgba(11, 83, 148, 0.06)",
                        withdateranges: true,
                        range: "YTD",
                        hide_side_toolbar: false,
                        allow_symbol_change: true,
                        details: true,
                        hotlist: true,
                        calendar: true,
                        show_popup_button: true,
                        popup_width: "1000",
                        popup_height: "650",
                        container_id: "tradingview_51c4a"
                    });
                }
            }
        },
        []
    );

    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br/>
      <br/>
            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        <h3><small>Gold Live Chart</small></h3>
                        <p className="texts" ><small>The <b>Gold Live Chart</b> provides real-time gold prices updated every second without having to refresh the page. In our gold chart, various tools can be used to understand the full picture of the bullion market.
                        </small></p>
                    </div>
                </div>
            </div>
            <div className="album py-2">

                <div className="container">
                    <div className='tradingview-widget-container' style={{ height: "880px", width: "100%" }}>
                        <div id='tradingview_51c4a' style={{ height: "calc(100% - 32px)", width: "100%" }} />
                        <div className="tradingview-widget-copyright">
                            <a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <h3><small>How to Read Gold Live Chart?</small></h3>
                        <p className="texts" ><small>Gold Live Chart is a powerful tool that can be used by gold traders to take advantage of the movement of the gold (XAU/USD) price. Gold Live Chart gives you real-time insight into what is happening in the gold price. The gold live chart has become an important tool for gold traders and gold investors around the world.
                        </small></p>
                        <p className="texts"><small>There is no charge to use the Live Gold chart. The gold chart gives you unlimited access to live prices on the live XAU/USD chart. You can also read our latest gold price forecast. When you are familiar with how to use the gold live chart, you can also view other precious metals such as silver price, platinum price, palladium price, and many more.</small></p>
                    </div>
                </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <h3><small>Why Gold Live Chart is useful?</small></h3>
                        <p className="texts"><small><b>Gold Live Chart is widely followed in global markets.</b> For hundreds of years, gold was the basis of economic capitalism until the abolition of the Gold Standard. Which led to the expansion of the fiat monetary system. In which paper money was not supported implicitly by any physical form of monetization. <b>XAUUSD</b> is the symbol of Gold.
                        </small></p>
                        <p className="texts"><small>“Gold Live chart” can be useful in several ways. For those who wish to actively trade yellow metals such as gold, rather than investing for the long term. Short-term traders or investors may examine the price data for the short time frame. Looking for support and resistance areas for buying and selling.</small></p>
                        <p className='mb-5'><small>Short-term traders or investors can also use the gold live chart to identify trends to take advantage of trading. Long-term investors can also take advantage of gold price charts. Long-term gold buyers try to use the gold live chart in a longer time frame. Such as four-hour, daily, weekly and monthly charts.</small></p>
                        <br />
                        <h3><small>What is Gold Trading?</small></h3>
                        <p className="texts"><small>Gold trading is the practice of speculating on the price of the gold market in order to make a profit from buying and selling. Typically, physical gold coins or gold bars are not processed during online gold trading.
                        </small></p>
                        <p className="texts"><small>When trading gold, you do not necessarily need to hold gold for a long time. You can use the gold live chart for short periods of time to trade in order to make money from scalping. You can analyze the gold live chart and take advantage of falling and rising prices in the market. No matter what position you take, the purpose of gold trading is to predict which direction the market will go.</small></p>
                        <br />
                        <br />
                        <h3><small>A brief history of gold</small></h3>
                        <p><small>While the history of gold began in 3000 BC, when the ancient Egyptians began to form jewelry, it was not until 560 BC. That gold began to act as a currency. After the emergence of gold as a source of money, its importance continued to grow throughout Europe and the United Kingdom. With the appearance of the monuments of the Greek and Roman empires prominently in museums around the world and Great Britain developed its own coin currency in 1066.

                        </small></p>
                    </div>
                </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <img src={Goldlivechart} className='img-fluid' alt="livechat" />
                    <br />
                    <br />
                    <p><small>The US government continued this golden tradition by setting up a bimetallic standard in 1792. The standard of mineralization simply stated that every monetary unit in the United States must be supported either by gold or silver.
                    </small></p>
                    <p className="texts"><small>But this golden standard did not last forever. During the 1900s, there were many major events that eventually led to the transition of gold from the monetary system. In 1913, the Fed was created and began issuing promissory notes (the current day version of our paper money) that can be replaced with gold on demand.</small></p>
                    <p className="texts"><small>The 1934 Gold Reserve Act gave the US government the right to all gold coins traded and put an end to the new gold coins. The United States abandoned the gold standard in 1971 when it stopped its currency backed by gold.</small></p>
                    <br />
                    <h3 className='texts'><small>The importance of gold in the modern economy</small></h3>
                    <p className="texts"><small>Given the fact that gold no longer supports the US dollar, why is it still important today? The simple answer is that although gold is no longer at the forefront of daily transactions. It remains important for the global economy. To verify this point, there is no need to look beyond the balance sheets of central banks and other financial organizations, such as the International Monetary Fund. At present, these organizations are responsible for holding nearly one-fifth of the world’s gold supply.</small></p>
                    <br />
                    <h3><small>Conclusion</small></h3>
                    <p><small>By looking at the price of gold live chat and other prices or economic indicators, traders may be able to better predict the future movement of gold price and profit from it.
                    </small></p>
                    <br />
                </div>
            </div>
        </div>
    );
}
