import React from 'react'
import Header from '../inc/Header'
import AddSupport from '../AddSupport'
import AddFundmanagerjob from '../AddFundjob'

function FundJob() {
  return (
    <div>
            <Header/>

            <br/>
            <br/>
            <br/>
            <br />
            <br />
            <br/>
            <center style={{color:"Darkblue"}} ><h2 className='mb-3'><b>FUND MANAGER JOB</b></h2></center>
            <AddFundmanagerjob/>
            <br/>

    </div>
  )
}

export default FundJob